import $ from 'jquery';
import { DataTableRenderType } from './datatable';

const getDefaultDataTableOptions = ($table: JQuery): DataTables.Settings => {
  const options: DataTables.Settings = {
    retrieve: true,
    fixedHeader: !!$table.data('dt-fixed-header'),
    paging: !!$table.data('paging'),
    pageLength: $table.data('page-length') || 0,
    columnDefs: [
      {
        targets: ['unsortable'],
        orderable: false,
      },
      {
        targets: ['id_col'],
        type: 'num',
      },
      {
        targets: ['quotes_due_col'],
        type: 'num',
        render(quotesDueHtml, type: DataTableRenderType) {
          if (type === 'display') return quotesDueHtml;
          return Math.max(
            ...$(quotesDueHtml)
              .find('.dateDue')
              .toArray()
              .map((d) => parseInt(d.dataset.timestamp || '0', 10)),
          );
        },
      },
      {
        targets: ['distance_col'],
        name: 'distance',
        type: 'num',
        render(distanceHtml: string, type: DataTableRenderType) {
          switch (type) {
            case 'filter':
            case 'sort': {
              return parseInt($(distanceHtml).data('metres'));
            }
            default:
              return distanceHtml;
          }
        },
      },
      {
        targets: ['date_col'],
        type: 'date',
        render(dateHtml: string, type: DataTableRenderType) {
          switch (type) {
            case 'filter':
            case 'sort':
              return $(dateHtml).data('date-timestamp') || dateHtml;
            default:
              return dateHtml;
          }
        },
      },
      {
        targets: ['interest_col'],
        name: 'interest_level',
        type: 'num',
        render(dropdownHtml: string, type: DataTableRenderType) {
          if (type === 'display') return dropdownHtml;
          const $dropdown = $(dropdownHtml);
          return window.global.account?.watchlistIds[$dropdown.data('project-id')] ?? 0;
        },
      },
    ],
    order: [[0, $table.data('sort') ? $table.data('sort') : 'asc']],
    dom: $table.data('dt-controls') ? $table.data('dt-controls') : 't',
  };

  if ($table.data('dt-scroll-y')) {
    return {
      ...options,
      scrollY: $table.data('dt-scroll-y'),
    };
  }

  return options;
};

export default function initialiseSortableTable(table: HTMLElement) {
  const $table = $(table);
  const $loadingContainer = $table.closest('.loading-container');
  const $tableContainer = $table.closest('.table-container');

  const dataTableOptions = getDefaultDataTableOptions($table);
  const dataTable = $table.DataTable(dataTableOptions);

  if ($table.data('dt-group-index')) {
    // used to avoid collisions when loading multiple document with clashing
    // group names.
    const prefix = $table.data('dt-group-prefix');
    $table.rowGrouping({
      iGroupingColumnIndex: $table.data('dt-group-index'),
      bExpandableGrouping: true,
      bSetGroupingClassOnTR: true,
      fnOnGroupCompleted(group) {
        const elem = group.nGroup;
        const $targetDiv = $(elem).find('td:first');
        const $folderIcon = $('<i>').addClass('icon-folder');

        if (prefix) {
          const prefixLength = prefix.length + 1;
          if (prefixLength < $targetDiv.text().length + 1) {
            $targetDiv.text(group.text.substring(prefixLength));
          }
        }

        $targetDiv.prepend($folderIcon);
        $table.trigger('datatable-row-init', $(elem));
      },
    });
  }

  if ($loadingContainer.length) {
    $loadingContainer.find('.loading').hide();
    $loadingContainer.find('.loaded').show();
    dataTable.columns.adjust().draw();
  }

  // Listen to distance and interest filters, if they exist
  const $distFilter = $tableContainer.find('select.project-distance-filter');
  const $interestFilter = $tableContainer.find('select.project-interest-filter');

  const { draw } = dataTable;

  if ($distFilter.length) {
    $distFilter.on('change', () => draw);
  }
  if ($interestFilter.length) {
    $interestFilter.on('change', () => draw);
  }

  const updateRowInterestById = (pid: number) => {
    const $updatedRow = $table.find('tr').has(`.interest-levels-dropdown[data-project-id=${pid}]`);

    const dt = $table.DataTable();

    if ($updatedRow.length) {
      const $assignee = $updatedRow.find('td').has('[data-hook="assign-project"]');
      if ($assignee.length) {
        $updatedRow.get(0)?.dispatchEvent(new Event('watchlist:assignee-cell-updating'));
        dt.cell($assignee).invalidate('dom');
      }

      dt.row($updatedRow).invalidate('data').draw(false);

      if ($assignee.length) {
        document.dispatchEvent(new Event('watchlist:table-updated'));
      }
    } else {
      dt.rows().invalidate('data').draw(false);
    }
  };

  $(document).on('watchlist:update', (_, projectId, status) => {
    updateRowInterestById(projectId);
    $(document).trigger('watchlist:postdraw', [projectId, status]);
  });
}
