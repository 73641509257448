import { Router } from 'symfony-ts-router';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const routes = require('./routes.json');

const router = new Router();

router.setRoutingData(routes);

export type RouterOptions = Record<string, string | number | boolean | string[] | undefined>;

const Routing = {
  generate: <T extends RouterOptions>(route: string, opts?: T): string => router.generate(route, opts)
};

export default Routing;
