export enum StageStatus {
  TENDER_PENDING = 1,
  TENDER_AWARDED = 2,
  TENDER_LOST = 3,
  TENDER_WITHDRAWN = 4,
  TENDER_ON_HOLD = 7,
  CONSTRUCTION_IN_PROGRESS = 5,
  CONSTRUCTION_ARCHIVED = 6,
}

export enum ProcurementStatus {
  PROCUREMENT_ACTIVE = 8,
}

export enum StageStatusName {
  TENDER_PENDING = 'Pending',
  TENDER_AWARDED = 'Awarded',
  TENDER_LOST = 'Lost',
  TENDER_WITHDRAWN = 'Withdrawn',
  TENDER_ON_HOLD = 'On hold',
  CONSTRUCTION_IN_PROGRESS = 'In Progress',
  CONSTRUCTION_ARCHIVED = 'Archived',
}

export const StageStatusMap = {
  [StageStatus.TENDER_PENDING]: StageStatusName.TENDER_PENDING,
  [StageStatus.TENDER_AWARDED]: StageStatusName.TENDER_AWARDED,
  [StageStatus.TENDER_LOST]: StageStatusName.TENDER_LOST,
  [StageStatus.TENDER_WITHDRAWN]: StageStatusName.TENDER_WITHDRAWN,
  [StageStatus.TENDER_ON_HOLD]: StageStatusName.TENDER_ON_HOLD,
  [StageStatus.CONSTRUCTION_IN_PROGRESS]: StageStatusName.CONSTRUCTION_IN_PROGRESS,
  [StageStatus.CONSTRUCTION_ARCHIVED]: StageStatusName.CONSTRUCTION_ARCHIVED,
};
