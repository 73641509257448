export type Response = {
  responseJSON?: { errors: { [k: string]: string } };
  error?: string;
  errors?: { [k: string]: string } | string[];
};

export default class RequestError extends Error {
  private readonly responseJSON: { errors: { [k: string]: string } } | null = null;

  private error: unknown;

  private readonly errors: { [k: string]: string } | string[];

  constructor(
    private readonly response?: Response,
    public message = 'Request unsuccessful',
  ) {
    super(message);

    Object.setPrototypeOf(this, new.target.prototype);

    this.name = 'RequestError';
    this.message = message;
    this.response = response;
    this.errors = {};

    if (this.response) {
      this.responseJSON = this.response.responseJSON || null;
      this.error = this.response.error || null;
      this.errors =
        this.response.errors || (this.responseJSON && this.responseJSON.errors) || this.errors;
    }
  }

  public getErrors() {
    return this.errors;
  }
}
