/**
 * See: {@link https://github.com/estimateone/ascension/blob/master/src/E1/AppBundle/Entity/Stage.php}
 */
export enum StageType {
  TYPE_TENDER = 1,
  TYPE_PROCUREMENT = 4,
}

export const getStageTypeAsString = (stageType: StageType): string | undefined => {
  switch (stageType) {
    case StageType.TYPE_TENDER:
      return 'Tender';
    case StageType.TYPE_PROCUREMENT:
      return 'Procurement';
    default:
      return undefined;
  }
};
