import $ from 'jquery';
import Cookies from 'js-cookie';

$(() => {
  $('[data-dismiss-cookie-warning]').on('click', (e) => {
    e.preventDefault();
    Cookies.set('e1_cookie_warning_dismissed', '1', {
      expires: 365,
      path: '/',
    });
    $(e.currentTarget).parents('.cookie-warning').fadeOut(500);
  });
});
