import $ from 'jquery';
import { captureMessage } from '@sentry/browser';
import escape from 'lodash/escape';
import _isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import E1Request, { E1Response } from './E1Request';
import { isMobileSafari } from '../utils/helpers';
import Modal from './Modal';
import SuperSlider from './SuperSlider';
import ValidationError from './error/ValidationError';

const CLASSES = {
  GLOBAL_ERROR: 'global-error',
  HAS_ERROR: 'has-error',
  ERR_MSG: 'error-msg',
  FORM_GROUP: 'form-group',
  HELP_BLOCK: 'help-block',
  NOTIFY_FORM_SUCCESS: 'form-success-notification',
  LOADING: 'loading-indicator',
  LOADED: 'loaded-indicator',
  EXTRA_LOAD: 'glyphicon glyphicon-refresh glyphicon-spin',
};

export default class Form<Response extends E1Response> {
  private readonly data: Record<string, unknown>;
  /**
   * @deprecated Do not use. We should use promises (async / await) in favour of callbacks
   */
  public extraCallback: ((response: Response) => void) | null;
  public success: boolean | null;
  public request: E1Request<Response> | null;

  constructor(
    private readonly $target: JQuery<HTMLElement>,
    private readonly url?: string,
    private readonly method?: string,
  ) {
    this.$target = $target;
    this.url = url || this.$target.attr('action');
    this.method = method || this.$target.attr('method');
    this.data = {};
    this.extraCallback = null;
    this.success = null;
    this.request = null;

    // Prevent default form submissions if handled by this class.
    this.$target.on('submit', (submitEvent) => submitEvent.preventDefault());
  }

  static get globalErrorClass(): string {
    return CLASSES.GLOBAL_ERROR;
  }

  static get hasErrorClass(): string {
    return CLASSES.HAS_ERROR;
  }

  static get errorMsgClass(): string {
    return CLASSES.ERR_MSG;
  }

  static get formGroupClass(): string {
    return CLASSES.FORM_GROUP;
  }

  static get helpBlockClass(): string {
    return CLASSES.HELP_BLOCK;
  }

  static get formNotificationClass(): string {
    return CLASSES.NOTIFY_FORM_SUCCESS;
  }

  static get buttonLoadClass(): string {
    return CLASSES.LOADING;
  }

  static get buttonLoadedClass(): string {
    return CLASSES.LOADED;
  }

  static get buttonExtraLoadedClass(): string {
    return CLASSES.EXTRA_LOAD;
  }

  activateLoading(): void {
    const $button = $('button[type=submit]', this.$target);

    if (!$button.find(`.${Form.buttonLoadClass}`).length) {
      $button.prepend($('<i>').addClass(`${Form.buttonLoadClass} ${Form.buttonExtraLoadedClass}`));
    }
    $button.find(`.${Form.buttonLoadedClass}`).addClass('hide');
    $button.attr('disabled', 'disabled');
  }

  deactivateLoading(): void {
    const $button = $('button[type=submit]', this.$target);

    $button.find(`.${Form.buttonLoadClass}`).remove();
    $button.find(`.${Form.buttonLoadedClass}`).removeClass('hide');
    $button.attr('disabled', null);
  }

  resetForm(): void {
    this.$target
      .find(':input')
      .not(':button, :submit, :reset, :hidden')
      .val('')
      .removeAttr('checked')
      .removeAttr('selected');
  }

  preSubmit(): void {
    this.$target.find(`.${Form.formNotificationClass}`).remove();
    this.$target.find(`.${Form.errorMsgClass}`).remove();
    this.$target.find(`.${Form.hasErrorClass}`).removeClass(Form.hasErrorClass);
    this.$target.find(`.${Form.globalErrorClass}`).empty();
    this.activateLoading();
  }

  postSubmit(): void {
    $('button[type=submit]', $(this).closest('form')).removeAttr('clicked');
  }

  static unflattenSerialisedArray<D extends { name: string; value: unknown }>(
    formData: D[],
    $button: JQuery,
  ): Record<string, unknown> {
    const button = $button.val();

    const unflattenedFormData: { [k: string]: unknown } = {};
    formData.forEach(({ name: n, value: v }) => {
      const keys = n.match(/[a-zA-Z0-9_]+|(?=\[\])/g);
      // eslint-disable-next-line fp/no-let
      let unflattenedKey: Record<string, unknown> = {};

      (keys || []).reverse().forEach((k, i) => {
        if (i === 0) {
          /* eslint-disable fp/no-mutation */
          unflattenedKey[k] = v;
          if (!k) {
            (unflattenedKey as unknown) = [v];
          }
        } else {
          unflattenedKey = { [k]: unflattenedKey };
        }
      });
      mergeWith(unflattenedFormData, unflattenedKey, (ov, sv) => {
        if (_isArray(ov)) return ov.concat(sv);
        return undefined;
      });
    });
    if (button) {
      unflattenedFormData[button.toString()] = 1;
    }
    return unflattenedFormData;
    /* eslint-enable */
  }

  gatherInputs(): Record<string, unknown> {
    // eslint-disable-next-line fp/no-let
    let formData;

    /* eslint-disable fp/no-mutation */
    if (isMobileSafari()) {
      /*
      Select optgroup options are user-selectable in Safari on iOS,
      but they are ignored by serializeArray()
      Enable them before serialisation and let validation on the backend sort it out.
       */
      const $disabledOptions = this.$target.find('select option:disabled');

      $disabledOptions.prop('disabled', false);
      formData = this.$target.serializeArray();
      $disabledOptions.prop('disabled', true);
    } else {
      formData = this.$target.serializeArray();
    }
    /* eslint-enable */
    return Form.unflattenSerialisedArray(formData, $('button[type=submit][clicked=true]'));
  }

  getData(): Record<string, unknown> {
    return this.data;
  }

  showError(key: string, error: string): void {
    const $msg = $('<span>').addClass(Form.helpBlockClass).append(escape(error));

    const $group = this.$target.find(`*[id^="${key}"]`).closest(`.${Form.formGroupClass}`);

    if ($group.length) {
      $group
        .addClass(Form.hasErrorClass)
        .append($('<div>').addClass(Form.errorMsgClass).append($msg));
    } else {
      // eslint-disable-next-line fp/no-let
      let $errorCtn = $(`.${Form.globalErrorClass}`);
      if (!$errorCtn.length) {
        // eslint-disable-next-line fp/no-mutation
        $errorCtn = $('<div>').addClass(Form.globalErrorClass);
        this.$target.prepend($errorCtn);
      }
      $errorCtn.addClass(Form.hasErrorClass).append($msg);
      $errorCtn[0].scrollIntoView();
    }
  }

  showErrors(name: string, form: unknown): void {
    (_isArray(form) ? form : [form]).forEach((e) => this.showError(name, e));
  }

  replaceForm(message: string): void {
    this.$target.replaceWith($('<p>').addClass('form-replace-message').text(message));
  }

  static async handleAJAXResponse<Response extends E1Response>(
    self: Form<Response>,
    response: Response,
  ): Promise<void> {
    self.postSubmit();
    // Setup default operations for response
    self.$target.trigger('form-submitted-success', response);
    if (response.redirect) {
      window.location.assign(response.redirect);
      return;
    }
    if (response.modal_string) {
      Modal.closeAll();
      const modal = new Modal(response.modal_string);
      modal.show();
    }
    if (response.close_modal) {
      Modal.closeAll();
    }
    if (response.close_slider) {
      SuperSlider.closeAll();
    }
    if (response.form_replace_message) {
      self.replaceForm(response.form_replace_message);
    }
    if (response.extra_event) {
      document.dispatchEvent(new CustomEvent(response.extra_event));
    }
    if (response.extra_request) {
      await new E1Request(response.extra_request).submit();
    }
    if (response.flash_notification) {
      E1Request.flashNotification(response.flash_notification);
    }
    if (response.reload_page) {
      window.location.reload();
    }
    if (self.extraCallback) {
      self.extraCallback(response);
    }
    self.deactivateLoading();
  }

  async submit(): Promise<Response | { success: false }> {
    this.preSubmit();
    // There needs to be some handing of re-submitting, cancelling the existing request, etc.
    if (this.request !== null && this.request.isPending()) {
      this.request.abort();
    }

    this.request = new E1Request<Response, { [p: string]: unknown }>(
      this.url as string, // Let's assume this is set at this point
      this.method,
      this.gatherInputs(),
    );

    const onRequestFailure = (
      context: Form<Response>,
      data: JQueryXHR,
      textStatus: string,
      _: E1Request<Response>,
    ) => {
      context.deactivateLoading();
      E1Request.errorResponse(context, data, textStatus, _);
    };

    try {
      return await this.request.submit(Form.handleAJAXResponse, onRequestFailure, this);
    } catch (reqErr) {
      if (reqErr instanceof ValidationError) {
        const errors = reqErr.getErrors();
        this.$target.trigger('form-submitted-errors');
        // eslint-disable-next-line fp/no-let
        let hasCsrfTokenError = false;

        if (_isArray(errors)) {
          this.showErrors('__global', errors);
        } else {
          Object.keys(errors).forEach((formError) => {
            this.showErrors(formError, errors[formError]);
            if (formError === '_global') {
              const err = [errors[formError]];

              // eslint-disable-next-line fp/no-mutation
              hasCsrfTokenError = hasCsrfTokenError || err.join().includes('page open too long');
            }
          });
        }

        if (hasCsrfTokenError) {
          await this.sendCsrfInvalidTokenEvent();
        }
        // validation errors aren't exceptions
        return Promise.resolve({ success: false });
      }

      return Promise.reject(reqErr);
    }
  }

  async sendCsrfInvalidTokenEvent(): Promise<void> {
    if (window.analyticsService) {
      await window.analyticsService.addErrorEvent(
        { action: 'CsrfTokenInvalid', requestUrl: this.url ?? 'unknown request url' },
        '',
      );
    }
    if (this.$target.hasClass('signup-form') || this.$target.hasClass('signup-form-marketing')) {
      captureMessage('CSRF Mismatch on signup form');
    }
  }
}
