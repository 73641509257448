export enum QuoteFormCommonFieldName {
  Amount = 'quoteAmount',
  Details = 'quoteDetails',
  ExtraFiles = 'quoteExtraFiles',
  QuoteFile = 'quoteFile',
  ConfirmFixedPrice = 'confirmFixedPrice',
}

export enum QuoteFormCommonPropertyPath {
  Amount = 'amount',
  Details = 'details',
  ExtraFiles = 'extraFiles',
  QuoteFile = 'quoteFile',
  ConfirmFixedPrice = 'confirmFixedPrice',
}

export enum QuoteFormWithoutExtraFileFieldName {
  Amount = 'quoteAmount',
  Details = 'quoteDetails',
  QuoteFile = 'quoteFile',
}

export const QuoteFormCommonPropertyFieldMap = {
  [QuoteFormCommonPropertyPath.Amount]: QuoteFormCommonFieldName.Amount,
  [QuoteFormCommonPropertyPath.Details]: QuoteFormCommonFieldName.Details,
  [QuoteFormCommonPropertyPath.ExtraFiles]: QuoteFormCommonFieldName.ExtraFiles,
  [QuoteFormCommonPropertyPath.QuoteFile]: QuoteFormCommonFieldName.QuoteFile,
  [QuoteFormCommonPropertyPath.ConfirmFixedPrice]: QuoteFormCommonFieldName.ConfirmFixedPrice,
};

export enum QuoteFormCommonFieldError {
  AmountEmpty = 'Please enter an amount',
  QuoteFileEmpty = 'Please upload your quote',
}
