export enum BillingCycle {
  Months0 = 0,
  Months1 = 1,
  Months3 = 3,
  Months6 = 6,
  Months12 = 12,
  Months14 = 14,
  Months24 = 24,
  Months36 = 36,
}

export const BillingCycleText = {
  [BillingCycle.Months0]: '-',
  [BillingCycle.Months1]: 'Monthly',
  [BillingCycle.Months3]: 'Quarterly',
  [BillingCycle.Months6]: 'Half Yearly',
  [BillingCycle.Months12]: 'Annually',
  [BillingCycle.Months14]: '14 months',
  [BillingCycle.Months24]: '24 months',
  [BillingCycle.Months36]: '36 months',
};

export enum AddonName {
  ConsultantDetails = 'CONSULTANT_DETAILS',
}

export enum ProductType {
  // Subbie
  SubbieFree = 'Free',
  SubbieEssential = 'Essential',
  SubbieEssentialNational = 'Essential National',
  // Supplier
  SupplierStarter = 'Starter',
  SupplierStandard = 'Standard',
  SupplierProfessional = 'Professional',
}

export enum ProductId {
  National = 2,
  VicTas = 41,
  NswAct = 42,
  QldNt = 43,
  SaNt = 44,
  WaNt = 45,

  ProfessionalPlusNationalLegacy = 64,
  ProfessionalPlusNational = 65,
  ProfessionalPlusWaNt = 70,
  ProfessionalPlusVicTas = 77,
  ProfessionalPlusNswAct = 78,
  ProfessionalPlusQldNt = 79,
  ProfessionalPlusSaNt = 80,
  ProfessionalNational = 81,
  ProfessionalVicTas = 82,
  ProfessionalNswAct = 83,
  ProfessionalQldNt = 84,
  ProfessionalSaNt = 85,
  ProfessionalWaNt = 86,
}
