import $ from 'jquery';
import E1Address from './E1Address';
import Form from './Form';

export default class ManualAddressForm {
  constructor($target, autoCompleteParent) {
    this.$target = $target;
    this.autoCompleteParent = autoCompleteParent;

    const addressForm = new Form(this.$target);

    this.$target.on('submit', async (submitEvent) => {
      submitEvent.preventDefault();
      await addressForm.submit();
      const address = this.extractE1Address();
      this.autoCompleteParent.setInputBasedOnAddressFields(address);
      this.autoCompleteParent.setAddressFields(address);
      return false;
    });
  }

  extractE1Address() {
    const e1Address = new E1Address();
    const $stateField = this.$target.find('.state-field');

    e1Address.address = this.$target.find('.address-one-field').val();
    e1Address.address2 = this.$target.find('.address-two-field').val();
    e1Address.address3 = this.$target.find('.address-three-field').val();
    e1Address.suburb = this.$target.find('.suburb-field').val();
    e1Address.city = this.$target.find('.city-field').val();
    e1Address.province = this.$target.find('.province-field').val();
    e1Address.state = $.isNumeric($stateField.val())
      ? $stateField.find('option:selected').text()
      : null;
    e1Address.country = this.$target.find('.country-field').find('option:selected').text();
    e1Address.postcode = this.$target.find('.postcode-field').val();
    e1Address.latitude = this.$target.find('.latitude-field').val();
    e1Address.longitude = this.$target.find('.longitude-field').val();

    return e1Address;
  }
}
