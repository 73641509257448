import { captureMessage } from '@sentry/browser';

export default class Throttler {
  request: (() => void) | null;
  delay: number;
  pending: boolean;

  constructor() {
    this.request = null;
    this.delay = 400;
    this.pending = false;
  }

  add(newRequest: () => void) {
    this.request = newRequest;
    if (!this.pending) {
      this.pending = true;
      setTimeout(() => {
        this.submit();
      }, this.delay);
    }
  }

  submit() {
    const { request } = this;
    this.request = null;
    this.pending = false;
    if (!request) {
      captureMessage('Called throttler submit without a request');
      return;
    }
    request();
  }
}
