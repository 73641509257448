import $ from 'jquery';
import Routing from 'routing';
import { getUserId } from '../../utils/helpers';
import { AnalyticsPayload } from '@ascension/js/classes/AnalyticsService/types';

interface AnalyticsData {
  collection: string;
  payload: AnalyticsPayload;
  uri?: string;
  logChannel?: string;
}

const post = async (endpoint: string, data: AnalyticsData): Promise<boolean> =>
  new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    $.post({ url: endpoint, data, dataType: 'json' })
      .done(() => resolve(true))
      .fail(() =>
        // The vast majority of errors here are due to the user being logged out.
        resolve(false),
      );
  });

export default class AnalyticsService {
  private readonly addEventEndpoint: string;

  constructor(addEventEndpoint?: string) {
    this.addEventEndpoint = addEventEndpoint || Routing.generate('app_ajax_analytics_event');
  }

  async addEvent(
    collection: string,
    payload: AnalyticsPayload,
    pageUri?: string,
    logChannel?: string,
  ): Promise<boolean> {
    return post(this.addEventEndpoint, {
      collection,
      payload,
      uri: pageUri || window.location.pathname + window.location.search + window.location.hash,
      logChannel,
    });
  }

  async addInteractEvent(
    payload: AnalyticsPayload,
    pageUri?: string,
    logChannel?: string,
  ): Promise<boolean> {
    return this.addEvent('interact', payload, pageUri, logChannel);
  }

  async addErrorEvent(payload: AnalyticsPayload, pageUri: string): Promise<boolean> {
    return getUserId() ? this.addEvent('error', payload, pageUri, 'issues') : false;
  }
}
