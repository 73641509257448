export enum UploadCategory {
  CORR = 'corr',
  DOC = 'doc',
  FILE = 'file',
  QUOTE = 'quote',
  QUOTE_EXTRA_FILE = 'quote_extra_file',
}

export enum UploadParentType {
  STAGE = 'stage',
  ADDENDUM = 'addendum',
  AB_COMPANY = 'abuploadfile',
  STAGE_FILE = 'stagefile',
  ACCOUNT_FILE = 'accountfile',
  USER_CORR_FILE = 'usercorrfile',
  USER_CORR_ATTACH = 'usercorrattach',
  USER_CORR_EMAIL = 'usercorremail',
  RFQ_CORR_FILE = 'rfqcorrfile',
  RFQ_CORR_ATTACH = 'rfqcorrattach',
  QUOTE = 'quote',
  STAGE_QUOTE = 'stagequote',
  PROJECT_QUOTE = 'projectquote',
  RFQ_QUOTE = 'rfqquote',
  QUOTE_EXTRA_FILE = 'quoteextrafile',
  STAGE_QUOTE_EXTRA_FILE = 'stagequoteextrafile',
  PROJECT_QUOTE_EXTRA_FILE = 'projectquoteextrafile',
  RFQ_QUOTE_EXTRA_FILE = 'rfqquoteextrafile',
}

export enum UploadRestriction {
  MaxEmailAttachmentSize = 24.5 * 1e6, // 24.5MB
}
