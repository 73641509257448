const ellip = '...';

/**
 * @param input - The string to be truncated
 * @param maxLength - The maximum output length
 */
export const truncate = (input: string, maxLength: number): string => {
  if (!input) return '';
  const inputLen = input.length;

  if (inputLen <= maxLength) return input;

  const left = input.substring(0, maxLength - ellip.length);

  return `${left}${ellip}`;
};

/**
 * @param input - The string to be truncated
 * @param maxLength - The maximum output length
 */
export const truncateMiddle = (input: string, maxLength: number): string => {
  if (!input) return '';
  const inputLen = input.length;
  if (inputLen <= maxLength) return input;

  const ellipLen = ellip.length + 2;
  const substringLen = Math.floor((maxLength - ellipLen) / 2);

  const left = input.substring(0, substringLen);
  const right = input.substring(inputLen - substringLen);

  return [left, ellip, right].join(' ');
};
