import $ from 'jquery';
import Routing from 'routing';
import { captureException } from '@sentry/browser';
import _ from 'underscore';
import E1Request from '../classes/E1Request';

function hideAddressFields($form) {
  $form.find('.address-fields').slideUp();
}

export function getAddressString(address) {
  let addressString = _.escape(address.address1);
  if (address.address2 !== null) {
    addressString += `<br />${_.escape(address.address2)}`;
  }
  addressString += `<br />${_.escape(address.suburb)}`;
  addressString += `<br />${_.escape(address.city)}`;
  if (address.country.id === 1) {
    addressString += `, ${_.escape(address.state.shortName)} ${_.escape(address.postcode)}`;
  }
  return addressString;
}

export function getShortAddress(address) {
  const shortAddress = [];
  if (address) {
    if (address.suburb) {
      shortAddress.push(address.suburb);
    } else if (address.city) {
      shortAddress.push(address.city);
    }

    if (address.province) {
      shortAddress.push(address.province);
    } else if (address.state) {
      shortAddress.push(address.state.shortName);
    }
  }
  return _.escape(shortAddress.join(', '));
}

export function getBoundsForLocaleBias() {
  const locale = window.global.locale || 'en_AU';
  let bounds = [];

  try {
    switch (locale) {
      case 'en_GB':
        bounds = [new google.maps.LatLng(49.0, -10.0), new google.maps.LatLng(59.0, 2.0)];
        break;
      case 'en_IE':
        bounds = [new google.maps.LatLng(52.0, -10.0), new google.maps.LatLng(55.0, 6.0)];
        break;
      case 'en_NZ':
        bounds = [new google.maps.LatLng(-48.0, 166.0), new google.maps.LatLng(-34.0, 179.0)];
        break;
      case 'en_PH':
        bounds = [new google.maps.LatLng(5.0, 117.0), new google.maps.LatLng(19.0, 127.0)];
        break;
      case 'en_AU':
      default:
        bounds = [new google.maps.LatLng(-44.0, 112.0), new google.maps.LatLng(-9.0, 154.0)];
    }

    return new google.maps.LatLngBounds(...bounds);
  } catch (err) {
    // google is probably blocked eg. china
    captureException(err);
  }

  return null;
}

function updateStateSelector(country, $form) {
  const $stateSelector = $form.find('.state-selector');

  $stateSelector.find('option:gt(0)').remove();
  loadStates(country, (states) => {
    $(states).each((index, state) => {
      $stateSelector.append($('<option>', { value: state.id, text: state.shortName }));
    });
    if ($stateSelector.find('option').length <= 1) {
      $stateSelector.closest('.form-group').slideUp();
    } else if (!$stateSelector.is(':visible')) {
      $stateSelector.closest('.form-group').slideDown();
    }
  });
}

function showAddressFields(form, country) {
  const $addressFields = $(form).find('.address-fields');
  const groups = $addressFields.find('.form-group');
  groups.each((i, field) => {
    const $field = $(field);
    if ($field.hasClass(`address_form_country_hide_${country}`)) {
      $field.slideUp();
    } else {
      $field.slideDown();
    }
  });
  $addressFields.slideDown();
}

function onAddressFormCountryChange($selector, $form) {
  const country = parseInt($selector.val());
  updateStateSelector(country, $form);
  if (country > 0) {
    showAddressFields($form, country);
  } else {
    hideAddressFields($form);
  }
}

function loadStates(country, cb) {
  let states = [];
  const countryId = parseInt(country);
  if (countryId > 0) {
    const path = Routing.generate('app_ajax_loadstates', { id: country });
    const request = new E1Request(path);

    request.submit((req, response) => {
      if (response.success) {
        states = JSON.parse(response.states);
        cb(states);
      } else {
        cb(states);
      }
    });
  } else {
    cb(states);
  }
}

function getGeocoder() {
  try {
    return new google.maps.Geocoder();
  } catch (err) {
    captureException(err);
  }
  return null;
}

export function initAddressForm(addressForm) {
  if ($(addressForm).hasClass('no-country')) {
    const $selector = $(addressForm).find('select.country-selector');
    const $options = $selector.find('option');
    const $target = $options.first();
    $target.prop('selected', true);
    $selector.trigger('change');
  }

  const $longitude = $(addressForm).find('input.longitude-field');
  const $latitude = $(addressForm).find('input.latitude-field');

  if ($longitude.length > 0 && $latitude.length > 0) {
    const c = { counter: 0 };
    $(addressForm).on(
      'change',
      ':input',
      _.debounce(() => {
        $longitude.val(null);
        $latitude.val(null);

        const addressArray = $(addressForm).find('input:visible').serializeArray();
        let addressString = '';
        $.each(addressArray, (key, addressPart) => {
          addressString += ` ${addressPart.value}`;
        });
        $(addressForm)
          .find('option:selected')
          .each(function () {
            addressString += ` ${$(this).text()}`;
          });

        const geocoder = getGeocoder();
        if (geocoder) {
          const currentCounter = c.counter + 1;
          c.counter = currentCounter;
          geocoder.geocode({ address: addressString }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && currentCounter === c.counter) {
              const lngLat = results[0].geometry.location;
              $longitude.val(lngLat.lng());
              $latitude.val(lngLat.lat());
            }
          });
        }
      }, 200),
    );
  }
}

$(window).on('load', () => {
  const { geoAddressId: addressId, geoAddressFormatted: address } = $('body').data();
  if (addressId && address) {
    const geocoder = getGeocoder();
    if (geocoder) {
      geocoder.geocode({ address }, async (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const lngLat = results[0].geometry.location;
          try {
            await new E1Request(
              Routing.generate('app_ajax_geolocateaddress', { id: addressId }),
              'POST',
              {
                lat: lngLat.lat(),
                lng: lngLat.lng(),
              },
              true,
            ).submit();
          } catch (geolocateFailed) {
            throw Error('Failed to geolocate address');
          }
        }
      });
    }
  }
});

$(() => {
  $('body').on('change', '.auto_address_form select.country-selector', (e) => {
    const $countrySelector = $(e.currentTarget);
    const $form = $countrySelector.closest('.auto_address_form');

    onAddressFormCountryChange($countrySelector, $form);
  });
  const $addressForm = $('.auto_address_form');

  if ($addressForm.length > 0) {
    $.each($addressForm, (i, val) => {
      initAddressForm(val);
    });
  }
});
