import $ from 'jquery';
import Routing from 'routing';
import Cookies from 'js-cookie';
import Moment from 'moment';
import E1Request from '../classes/E1Request';
import AnalyticsService from '../classes/AnalyticsService';
import Throttler from '../classes/Throttler';
import initialiseSortableTable from '../utils/sortable_table';
import { truncate } from '../utils/truncate';
import { getStageTypeAsString, StageType } from '../../enums';

window.segmentGroupCall = (identifier, payload) => {
  if (Cookies.get('e1_send_account_traits_to_segment')) {
    window.analytics.group(identifier, payload);
    Cookies.remove('e1_send_account_traits_to_segment');
  }
};

$(document)
  .on('mouseover', '.tooltip-trigger', (tooltipMouseOverEvent) => {
    const $tooltipTrigger = $(tooltipMouseOverEvent.currentTarget);
    if (!$tooltipTrigger.hasClass('tooltipstered')) {
      const contentClass = $tooltipTrigger.data('tooltip-content-class');
      const tooltipText = $tooltipTrigger.attr('title');
      const tooltip = contentClass
        ? `<span class="${contentClass}">${tooltipText}</span>`
        : tooltipText;
      const tooltipPosition = $tooltipTrigger.data('tooltip-position') ?? 'top';

      $tooltipTrigger
        .tooltipster({
          contentAsHTML: !!contentClass,
          content: tooltip,
          multiple: false,
          position: tooltipPosition,
        })
        .tooltipster('show');
    }
  })
  .on('mouseover', '.contact-type-indicator', (contactTypeMouseOverEvent) => {
    const $initiator = $(contactTypeMouseOverEvent.currentTarget);
    if (!$initiator.hasClass('tooltipstered')) {
      const suffix = $initiator.data('tip-suffix') || ' Contact';

      let prefix = 'Estimating';
      const source = $initiator.hasClass('source-profile') ? ', from Directory' : '';

      if ($initiator.hasClass('construction')) {
        prefix = $initiator.hasClass('estimating') ? 'Estimating & Construction' : 'Construction';
      }

      $initiator
        .tooltipster({
          content: `${prefix}${suffix}${source}`,
          multiple: false,
        })
        .tooltipster('show');
    }
  })
  .on('mfpOpen', () => {
    const dataUri = $.magnificPopup.instance.content.data('content-uri') || null;
    const blurBg =
      $.magnificPopup.instance.content.find('.modal-dialog').data('blur-background') || false;

    $('.container-fluid').toggleClass('blurred', blurBg);

    if (window.analyticsService && dataUri && window.global.user) {
      window.analyticsService.addEvent(
        'interact',
        {
          action: 'ViewModal',
        },
        dataUri,
      );
    }
  })
  .on('mfpClose', () => {
    $('.container-fluid.blurred').removeClass('blurred');
  });

$(async () => {
  window.analyticsService = new AnalyticsService();

  const recordPagePrint = async () =>
    window.analyticsService.addInteractEvent({
      action: 'PrintPage',
    });

  if (window.global.user) {
    await window.analyticsService.addInteractEvent({
      action: 'ViewPage',
      // dimensions in pixels
      screen: {
        width: screen.width,
        height: screen.height,
        vpHeight: document.documentElement.clientHeight,
        vpWidth: document.documentElement.clientWidth,
        agent: navigator.userAgent,
      },
    });
    if (!navigator.userAgent.indexOf('Firefox') && 'matchMedia' in window) {
      window.matchMedia('print').addListener(async (matchMediaEvent) => {
        if (matchMediaEvent.matches) {
          await recordPagePrint();
        }
      });
    } else {
      window.onbeforeprint = recordPagePrint;
    }
  }

  const $body = $('body');

  const commonTableClasses = ['table', 'dataTable', 'table-striped', 'no-footer'];

  /**
   * @param {jQuery} $headerCell
   * @return {String}
   */
  const getDatatableIdentifier = ($headerCell) => {
    // Attempt to get an identifier for the table
    // Get all the classes, remove common ones
    // Fall back to contents of
    // closest h4 otherwise (there is a common pattern of titling tables this way)
    // This approach is naive, but better than nothing
    const tableClasses = $headerCell
      .closest('table')
      .attr('class')
      .split(/\s+/)
      .filter((c) => !commonTableClasses.includes(c));

    return tableClasses
      ? tableClasses.join('|')
      : $headerCell.closest('h4').text().replace(' ', '_');
  };

  /**
   * @param {String} direction
   * @param {String} column
   * @param {String} tableId
   */
  const recordSortEvent = (direction, column, tableId = null) =>
    window.global.user &&
    window.analyticsService.addInteractEvent({
      action: 'ToggleColumnSort',
      direction,
      column,
      tableId,
    });

  $('table th').on('click', (headerClickEvent) => {
    const $headerCell = $(headerClickEvent.currentTarget);
    const columnName = $headerCell.text().toLowerCase().replace(' ', '_');

    if ($headerCell.hasClass('sorting_asc')) {
      recordSortEvent('ascending', columnName, getDatatableIdentifier($headerCell));
    }

    if ($headerCell.hasClass('sorting') || $headerCell.hasClass('sorting_desc')) {
      recordSortEvent('descending', columnName, getDatatableIdentifier($headerCell));
    }
  });

  const getRequestForSetting = (settingName) =>
    new E1Request(Routing.generate(`app_setting_${settingName}`), 'POST', [], true);

  let distanceChangePending = false;
  const distanceFilterChangeRequest = getRequestForSetting('distance');
  const interestFilterChangeRequest = getRequestForSetting('interest_filters');

  $body
    .on('change', 'select.project-distance-filter', async (changeDistanceFilterEvent) => {
      const $select = $(changeDistanceFilterEvent.currentTarget);
      if (!distanceChangePending) {
        distanceChangePending = true;
        distanceFilterChangeRequest.data = {
          distance: $select.val(),
        };
        await distanceFilterChangeRequest.submit();

        $('select.project-distance-filter')
          .not(changeDistanceFilterEvent.currentTarget)
          .toArray()
          .forEach((other) => {
            const $otherSelect = $(other);
            if ($otherSelect.hasClass('select2')) {
              if ($otherSelect.select2('val') !== $select.val()) {
                $otherSelect.select2('val', $select.val());
              }
            } else if ($otherSelect.val() !== $select.val()) {
              $otherSelect.val($select.val());
            }
          });
        distanceChangePending = false;
      }
    })
    .on('change', 'select.project-interest-filter', async (changeInterestFilterEvent) => {
      const $select = $(changeInterestFilterEvent.currentTarget);
      const chosenInterestLevels = $select
        .find('option:selected')
        .toArray()
        .map((opt) => parseInt($(opt).val(), 10));

      $select.multiselect('disable');

      interestFilterChangeRequest.data = {
        filter: $select.data('context'),
        setting: chosenInterestLevels,
      };

      await interestFilterChangeRequest.submit();
      $select.multiselect('enable');
    });

  const getDefaultInterestLevels = () =>
    Object.entries(window.global.watchlistStatuses || {})
      .filter((wle) => wle[1].toLowerCase() !== 'not interested')
      .map((wle) => parseInt(wle[0], 10));

  $('select.project-interest-filter[multiple="multiple"]')
    .toArray()
    .forEach((interestFilter) => {
      const $select = $(interestFilter);
      const filterAttr = 'data-initial-setting';
      const chosenFilters = $select.attr(filterAttr);

      $select.multiselect();

      if (chosenFilters) {
        $select.multiselect('select', JSON.parse(chosenFilters)).removeAttr(filterAttr);
      } else {
        // Select 'Unactioned', 'Interested', 'Quoting' and 'Quoted' if there is no user setting
        $select.multiselect('select', getDefaultInterestLevels());
      }
      $select.multiselect('updateButtonText');
    });

  $('select.select2:enabled:not([readonly])')
    .toArray()
    .forEach((select2) => {
      const $select2 = $(select2);
      const width = $select2.data('width') || 'resolve';

      $select2.select2({
        theme: 'bootstrap',
        minimumResultsForSearch: 10,
        width,
      });
    });

  // This utilises the stickytabs plugin to activate selected tabs on page load
  $('.nav-tabs').stickyTabs();

  // This stops the bootstrap dropdown from closing when it's clicked.
  $('#navvy .dropdown-menu input').on('click', (e) => e.stopPropagation());

  $('table.sortable-table')
    .toArray()
    .forEach((tbl) => initialiseSortableTable($(tbl)));

  const $projectSearchInput = $('.project_search_input');
  if ($projectSearchInput.length) {
    const searchThrottler = new Throttler();
    const applicationMode = parseInt($projectSearchInput.data('app-mode'), 10);

    $body.on('click', '.project_search_initiator', () => {
      setTimeout(() => {
        $projectSearchInput.trigger('focus');
      }, 100);
    });

    const searchRoute =
      applicationMode === 1
        ? Routing.generate('app_stage_search')
        : Routing.generate('app_constructionstage_search');

    const tenderSearchRequest = new E1Request(searchRoute, 'POST', {}, true);

    const $menu = $projectSearchInput.closest('.dropdown-menu');
    const $openStageContainer = $menu.find('.open_stages_container');
    const $resultsContainer = $menu.find('.project_search_results');
    const $resultsList = $resultsContainer.find('ul');

    // When the user clicks on a "disabled" span, this prevents the menu from closing
    const $disabledItems = $menu.find('.projects-menu-item-disabled');
    $disabledItems.on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });

    const toggleSearchDisplay = (search = true) => {
      if (search) {
        $openStageContainer.hide();
        $resultsContainer.show();
      } else {
        $openStageContainer.show();
        $resultsContainer.hide();
      }
    };
    /* Builder Project Menu Dropdown */
    $menu.on('keyup', '.project_search_input', (projectSearchKeyEvent) => {
      const $input = $(projectSearchKeyEvent.currentTarget);
      const q = $input.val().trim();
      const lenValid = q.length >= 3;

      if (lenValid) {
        tenderSearchRequest.data = { q };

        searchThrottler.add(async () => {
          const searchResponse = await tenderSearchRequest.submit();
          const { stages } = searchResponse;
          $resultsList.empty();

          if (!stages.length) {
            $resultsList.append($('<li><span class="small muted">No results</span></li>'));
            return;
          }

          const disabledTooltipText = $('.projects-menu').attr('data-disabled-tooltip-text');
          $resultsList.append(
            ...stages.map((stageResult) => {
              const { id, type, name, quotesDue, hasTeamAccessControl } = stageResult;
              const stageType = getStageTypeAsString(type);
              const targetRoute = stageType
                ? Routing.generate('app_stage_view', { id, stageType: stageType.toLowerCase() })
                : Routing.generate('app_constructionstage_view', { id });
              const date =
                type !== StageType.TYPE_PROCUREMENT ? Moment(quotesDue).format('MMM YYYY') : '-';
              const stageResultItem = stageResult.hasAccess
                ? $('<a class="projects-menu-item">').attr('href', targetRoute)
                : $(`<span
                  class="projects-menu-item-disabled tooltip-trigger"
                  title="${disabledTooltipText}"
                  data-tooltip-position="right"
                  data-tooltip-content-class="tooltip-confined-left">`);
              if (hasTeamAccessControl) {
                stageResultItem
                  .text(truncate(name, 25))
                  .append($('<span class="tag stage-label restricted-label">').text('Restricted'))
                  .append($('<span class="projects-menu-item-date">').text(date));
              } else {
                stageResultItem
                  .text(truncate(name, 25))
                  .append($('<span class="projects-menu-item-date">').text(date));
              }
              return $('<li>').append(stageResultItem);
            }),
          );

          // When the user clicks on a "disabled" span, this prevents the menu from closing
          const $disabledResultsList = $resultsList.find('.projects-menu-item-disabled');
          $disabledResultsList.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
          });
        });
      }
      toggleSearchDisplay(lenValid);
    });
  }

  const submitRequestWithOptionalLoader = async (route, showLoadingModal = true) =>
    new E1Request(Routing.generate(route)).setShowLoadingModal(showLoadingModal).submit();

  $body
    .on('click', '.upgrade-private-trigger', async () => {
      await submitRequestWithOptionalLoader('app_upgrade_private');
      return false;
    })
    .on('click', '.upgrade-addressbook-trigger', async () => {
      await submitRequestWithOptionalLoader('app_upgrade_addressbook');
      return false;
    });

  const shouldTriggerNpsModal = $body.data('nps-display');
  if (shouldTriggerNpsModal) {
    submitRequestWithOptionalLoader('app_account_npsmodal', false);
    return false;
  }

  const shouldRecommendRecentlyAwarded = $body.data('recently-awarded-display');
  if (shouldRecommendRecentlyAwarded) {
    new E1Request(Routing.generate('app_recommendation_recently_awarded'), 'GET', {
      trigger: 'modal',
    })
      .setShowLoadingModal(false)
      .submit();

    return false;
  }
});

$('body').on('click', '.select2-select-all', (selectAllEvent) => {
  const $select2 = $(selectAllEvent.currentTarget);
  const $fg = $select2.parent('.form-group');
  if ($fg.length) {
    const $select = $fg.find('select.select2');
    if ($select.length) {
      if ($select2.hasClass('selected')) {
        $select.find('option').removeAttr('selected');
        $select2.removeClass('selected');
      } else {
        $select.find('option').prop('selected', 'selected');
        $select2.addClass('selected');
      }
      $select.trigger('change');
    }
  }
});
