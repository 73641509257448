import DOMPurify from 'dompurify';

function sanitizeUrl(r) {
  if (!r) return 'about:blank';
  const t = r.replace(/[\\u0000-\\u001F\\u007F-\\u009F\\u2000-\\u200D\\uFEFF]/gim, '').trim();
  if (['.', '/'].indexOf(t[0]) > -1) return t;
  const a = t.match(/^([^:]+):/gm);
  if (!a) return t;
  const u = a[0];
  return /^([^\w]*)(javascript|data|vbscript)/im.test(u) ? 'about:blank' : t;
}

if (window.trustedTypes?.createPolicy) {
  window.trustedTypes.createPolicy('default', {
    createHTML: (string) => DOMPurify.sanitize(string, { RETURN_TRUSTED_TYPE: true }),
    createScriptURL: (string) => sanitizeUrl(string),
    createScript: (string) => string, // allow scripts
  });
}
