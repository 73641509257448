import $ from 'jquery';
import Modal from './Modal';

export default class LoadingModal {
  private modal: Modal | null = null;

  private readonly $modal: JQuery;

  constructor() {
    this.$modal = $('.e1_loading_modal');
    this.modal = null;
  }

  public show() {
    Modal.closeAll();
    this.modal = new Modal(this.$modal.prop('outerHTML'), {
      modal: false,
      closeOnContentClick: false,
      showCloseBtn: false,
      closeOnBgClick: false,
      enableEscapeKey: true,
    });
    this.modal.show();
  }

  public hide() {
    const current = $.magnificPopup.instance.currItem;
    if (current && current.data.src.hasClass('.e1_loading_modal')) {
      this.modal?.close();
    }
  }
}
