import RequestError, { Response } from './RequestError';

export default class TimeoutError extends RequestError {
  public code: string | null;

  constructor(
    response: Response,
    public message: string = 'timeout',
  ) {
    super(response, `Request timed out: ${message}`);
    Object.setPrototypeOf(this, new.target.prototype);
    this.code = null;
    this.name = 'TimeoutError';
  }
}
