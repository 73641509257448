/**
 * These magic numbers are the state's EntityId in the database
 */
export enum State {
  STATE_ACT = 1,
  STATE_QLD = 2,
  STATE_NSW = 3,
  STATE_VIC = 4,
  STATE_TAS = 5,
  STATE_SA = 6,
  STATE_WA = 7,
  STATE_NT = 8,
  STATE_EXT_TERRITORIES = 12,
}

export enum ShortStateName {
  STATE_ACT = 'ACT',
  STATE_QLD = 'QLD',
  STATE_NSW = 'NSW',
  STATE_VIC = 'VIC',
  STATE_TAS = 'TAS',
  STATE_SA = 'SA',
  STATE_WA = 'WA',
  STATE_NT = 'NT',
  STATE_EXT_TERRITORIES = 'EXT TERRITORIES',
}

export const ShortStateNameMap = {
  [State.STATE_ACT]: ShortStateName.STATE_ACT,
  [State.STATE_QLD]: ShortStateName.STATE_QLD,
  [State.STATE_NSW]: ShortStateName.STATE_NSW,
  [State.STATE_VIC]: ShortStateName.STATE_VIC,
  [State.STATE_TAS]: ShortStateName.STATE_TAS,
  [State.STATE_SA]: ShortStateName.STATE_SA,
  [State.STATE_WA]: ShortStateName.STATE_WA,
  [State.STATE_NT]: ShortStateName.STATE_NT,
  [State.STATE_EXT_TERRITORIES]: ShortStateName.STATE_EXT_TERRITORIES,
};
