export enum WatchlistStatus {
  UNACTIONED = -255,
  EXPLICITLY_RESET = -1,
  NOT_INTERESTED = 0,
  INTERESTED = 1,
  NOT_SUITABLE = 2,
  QUOTING = 3,
  QUOTED = 4,
  NEGOTIATING = 5,
  WON = 6,
  LOST = 7,
  ASSIGNED = 8,
}

export enum WatchlistSource {
  NOTICEBOARD = 'noticeboard',
  PROJECT_SLIDER = 'project-slider',
  SPECI_FINDER_KEYWORD_MATCHES_SLIDER = 'speci-finder-keyword-matches-slider',
  WATCHLIST = 'watchlist-table',
  SPECIFICATION_INSIGHTS_PROJECTS_TABLE = 'specification-insights-projects-table',
}
