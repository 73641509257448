export enum ConsultantTypeGroup {
  Architect = 'architect',
  Engineer = 'engineer',
  Other = 'other',
}

export enum ConsultantImportStatus {
  Imported = 'consultant.details.imported',
  Updated = 'consultant.details.updated',
  Skipped = 'consultant.details.skipped',
  ConsultantDetailsError = 'consultant.details.error',
  ConsultantOfficeError = 'consultant.office.error',
}
