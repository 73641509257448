import RequestError, { Response } from './RequestError';

export default class ValidationError extends RequestError {
  constructor(
    response: Response,
    public message: string = 'Validation failed',
  ) {
    super(response, message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'ValidationError';
  }
}
