/**
 * These magic numbers are the country's EntityId in the database
 */
export enum Country {
  COUNTRY_AUSTRALIA = 1,
  COUNTRY_UK = 4,
  COUNTRY_IE = 11,
}

export type CurrencyInfo = {
  currencySymbol: string;
  excludeTaxCode: string;
  currency: string;
};

export const defaultCurrencyInfo: CurrencyInfo = {
  currencySymbol: '$',
  excludeTaxCode: 'GST',
  currency: 'AUD',
};

const currencyInfoMap: Map<number, CurrencyInfo> = new Map<number, CurrencyInfo>([
  [Country.COUNTRY_AUSTRALIA, defaultCurrencyInfo],
  [Country.COUNTRY_UK, { currencySymbol: '£', excludeTaxCode: 'VAT', currency: 'GBP' }],
  [Country.COUNTRY_IE, { currencySymbol: '', excludeTaxCode: 'VAT', currency: 'EUR' }], // €
]);

export const getCurrencyInfo = (country?: Country) =>
  currencyInfoMap.get(country ?? Country.COUNTRY_AUSTRALIA) ?? defaultCurrencyInfo;
