import { AccountType as AccountTypeGenerated } from '@ascension/_gqltypes/admin.generated';

export enum AccountTypeNumeric {
  Builder = 1,
  Subcontractor = 2,
  Supplier = 6,
}

export const AccountTypeValue = {
  [AccountTypeGenerated.BUILDER]: AccountTypeNumeric.Builder,
  [AccountTypeGenerated.SUBCONTRACTOR]: AccountTypeNumeric.Subcontractor,
  [AccountTypeGenerated.E1]: 3,
  [AccountTypeGenerated.ARCHITECT]: 4,
  [AccountTypeGenerated.ENGINEER]: 5,
  [AccountTypeGenerated.SUPPLIER]: AccountTypeNumeric.Supplier,
};
