// This file is specifically for jQuery plugins since they do not map well to the webpack
// architecture and need to be handled specially.

// CSS Files
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';
import 'bootstrap-multiselect/dist/css/bootstrap-multiselect.css';
import 'bootstrap-toggle/css/bootstrap-toggle.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-fixedheader-dt/css/fixedHeader.dataTables.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'jstree/dist/themes/default/style.css';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-theme/dist/select2-bootstrap.css';
import 'simplebar/dist/simplebar.css';
import 'tooltipster/css/tooltipster.css';
import 'quill/dist/quill.snow.css';
import './dataTables/buttons.dataTables.css';

// jQuery plugin JS
import $ from 'jquery';
import 'jquery-migrate';
import 'jquery-ui';
import 'bootstrap-sass/assets/javascripts/bootstrap/alert';
import 'bootstrap-sass/assets/javascripts/bootstrap/button';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import 'bootstrap-sass/assets/javascripts/bootstrap/tooltip';
import 'bootstrap-sass/assets/javascripts/bootstrap/popover';
import 'bootstrap-sass/assets/javascripts/bootstrap/tab';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import 'bootpag/lib/jquery.bootpag';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import 'bootstrap-multiselect/dist/js/bootstrap-multiselect';
import 'bootstrap-notify/bootstrap-notify';
import 'bootstrap-toggle/js/bootstrap-toggle';
import 'fine-uploader/jquery.fine-uploader/jquery.fine-uploader';
import 'jquery.cookie/jquery.cookie';
import 'jquery-stickytabs/jquery.stickytabs';
import 'jstree/dist/jstree';
import 'magnific-popup/dist/jquery.magnific-popup';
import 'mark.js/dist/jquery.mark';
import 'simplebar/src/simplebar';
import 'tooltipster/js/jquery.tooltipster';
import 'typeahead.js/dist/typeahead.bundle';

// jQuery plugins that require initialisation
require('select2/dist/js/select2.full')(window, $);
require('datatables.net')(window, $);
require('datatables.net-fixedheader/js/dataTables.fixedHeader')(window, $);
require('datatables.net-buttons/js/dataTables.buttons')(window, $);
require('datatables.net-buttons/js/buttons.colVis')(window, $);

// Finally import plugins which rely on plugins which require initialisation
require('datatables.mark.js/dist/datatables.mark');
require('jquery-datatables-row-grouping/jquery.dataTables.grouping');
require('./dataTables/bootstrapPagination');
require('./dataTables/datetime-moment');
require('./dataTables/naturalSort');
