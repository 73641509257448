export interface E1AddressFields {
  address: string | null;
  address2: string | null;
  address3: string | null;
  suburb: string | null;
  province: string | null;
  city: string | null;
  state: string | null;
  postcode: string | null;
  country: string | null;
  longitude: number | null;
  latitude: number | null;
}

type E1AddressStringFields = keyof Omit<E1AddressFields, 'longitude' | 'latitude'>;

interface E1AddressInterface extends E1AddressFields {
  toObject(): E1Address;

  setProperty<K extends keyof E1AddressFields>(prop: K, value: E1AddressFields[K]): void;

  clone(): E1Address;

  toString(): string;

  toShortString(): string;
}

export default class E1Address implements E1AddressInterface {
  static addressFields: E1AddressStringFields[] = [
    'address',
    'address2',
    'address3',
    'suburb',
    'city',
    'province',
    'state',
    'postcode',
    'country',
  ];

  /**
   * @type Record<string, E1AddressStringFields[]>
   */
  static googleToE1Mapping: Record<string, E1AddressStringFields[]> = {
    country: ['country'],
    postal_code: ['postcode'],
    postal_town: ['city'],
    locality: ['suburb', 'city'],
    administrative_area_level_1: ['state', 'province'],
  };

  /**
   * @type string[]
   */
  static googleAddressParts = ['street_number', 'street_address', 'route', 'premise', 'subpremise'];

  constructor(
    public address: string | null = null,
    public address2: string | null = null,
    public address3: string | null = null,
    public suburb: string | null = null,
    public province: string | null = null,
    public city: string | null = null,
    public state: string | null = null,
    public postcode: string | null = null,
    public country: string | null = null,
    public longitude: number | null = null,
    public latitude: number | null = null, // eslint-disable-next-line no-empty-function
  ) {}

  /**
   * Map the curiously-named Google PlaceResult fields to E1Address normalisation
   */
  static fromGoogleResult(googleResult: google.maps.places.PlaceResult): E1Address {
    const e1Address = new E1Address();
    const { address_components: addressComponents, geometry } = googleResult;

    const location = geometry?.location ?? null;

    addressComponents?.forEach(({ types, long_name: longName }) => {
      const e1Fields = types.reduce<E1AddressStringFields[]>(
        (fields, t) =>
          E1Address.googleToE1Mapping[t] ? fields.concat(E1Address.googleToE1Mapping[t]) : fields,
        [],
      );

      e1Fields.forEach((e1field) => {
        // eslint-disable-next-line fp/no-mutation
        e1Address[e1field] = longName;
      });

      if (!e1Fields.length) {
        if (types.filter((t) => E1Address.googleAddressParts.includes(t)).length) {
          e1Address.setAddress(
            e1Address.address === null ? longName : `${e1Address.address} ${longName}`,
          );
        }
      }
    });

    if (location) {
      e1Address.setLatitude(location.lat());
      e1Address.setLongitude(location.lng());
    }

    e1Address.normalise();

    return e1Address;
  }

  setProperty = <K extends keyof E1AddressFields>(prop: K, value: (typeof this)[K]) => {
    this[prop] = value;
  };

  setAddress(address: string) {
    this.address = address;
  }

  setLatitude(lat: number) {
    this.latitude = lat;
  }

  setLongitude(lon: number) {
    this.longitude = lon;
  }

  /**
   * Right now, `suburb` and `state` are used for AU (and the USA).
   * Whilst `city` and `province` are used for everyone else.
   * But Google addresses don't cleanly map the same way, since we might map another country's `suburb` and then
   * display the `city` input instead.
   * So we map everything and then normalise the country.
   */
  public normalise() {
    if (this.country === 'Australia') {
      this.province = null;
      this.city = null;
      return;
    }

    this.suburb = null;
    this.state = null;
  }

  public toString() {
    return E1Address.addressFields
      .map((f) => this[f])
      .filter(Boolean)
      .join(' ');
  }

  /**
   * Formats the address to look like Google's suggestion
   */
  public toShortString() {
    const addressComponents: string[] = [];

    E1Address.addressFields
      .filter((f) => f !== 'postcode')
      .forEach((field) => {
        const value = this[field];
        if (value && value !== 'null') {
          if (field === 'province' && this.province && this.province === this.state) {
            return;
          }
          addressComponents.push(value);
        }
      });

    return addressComponents.join(', ');
  }

  public toObject(): E1Address {
    return { ...this };
  }

  public clone(): E1Address {
    // eslint-disable-next-line fp/no-mutating-assign
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this.toObject());
  }
}
