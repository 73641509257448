export enum ListenerEvent {
  AddressBookSliderContactAdded = 'addressbook_slider_contact_added',
  AddressBookSliderContactUpdated = 'addressbook_slider_contact_updated',
  AddressBookSliderContactRemoved = 'addressbook_slider_contact_removed',
  AddressBookSliderContactSetPreferred = 'addressbook_slider_contact_set_preferred',
  AddressBookCompanyUpdated = 'addressbookcompany_updated',
  AccountLicenseCreditCardUpdated = 'account_license_credit_card_updated',
  AccountLicenseDeleted = 'account_license_deleted',
  InviteToQuoteMultiple = 'invite_to_quote_multiple',
  InviteToQuoteSuccess = 'invite_to_quote_success',
  QuoteStatusUpdated = 'quote_status_updated',
  StageStatusUpdated = 'stage_status_updated',
}
