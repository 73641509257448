import $ from 'jquery';
import E1Request from './E1Request';
import { initAddressForm } from '../utils/address_form';
import initialiseSortableTable from '../utils/sortable_table';
import LocationAutocompleteWidget from './LocationAutocompleteWidget';

export default class SuperSlider {
  public $slider: JQuery;
  private convertedOptions: Record<string, unknown> & {
    callbacks?: Record<string, unknown> & {
      open: () => void;
      onClose: (...args: unknown[]) => void;
    };
  };
  public request: E1Request<Record<string, unknown> & { success: boolean }> | null;
  public url?: string;
  public extraCallback?: () => void;
  private readonly windowMargin = 0;
  // This is a no-op for compatibility with LoadingModal's API
  public hide = (): void => {
    /* do nothing */
  };
  private readonly name?: string;

  private containerSelector = '#default-super-slider';

  constructor(
    private readonly contents?: string,
    public options: Record<string, unknown> & {
      loading?: boolean;
      name?: string;
      callbacks?: Record<string, () => unknown>;
      data?: Record<string, unknown>;
      containerSelector?: string;
    } = { callbacks: {} },
  ) {
    this.containerSelector = options.containerSelector ?? this.containerSelector;
    this.$slider = $(this.containerSelector).find('.super-slider-outer');
    this.convertedOptions = {};
    this.request = null;
    this.url = undefined;
    if (this.options.name) {
      this.name = this.options.name;
    }

    this.init();
  }

  static addRootElementClasses() {
    $('body').addClass('super-slider-active');
    $('html').addClass('super-slider-body-scrolling');
  }

  static closeAll() {
    $('html').removeClass('super-slider-body-scrolling');
    $('body').removeClass('super-slider-active');
  }

  private init() {
    const $body = $('body');
    this.$slider.html('');
    if (this.options.loading) {
      this.$slider.append(
        $('<div>').addClass('super-slider-header'),
        $('<div>').addClass('loading-spinner-wb loading-spinner-small central'),
      );
    } else {
      if (this.contents) {
        this.$slider.html(this.contents);
      }
      this.convertedOptions.callbacks = {
        open: this.getCallbackOption('open', () => {
          const $sliderForm = this.$slider.find('form').first();
          if ($sliderForm !== null) {
            const $addressForms = $sliderForm.find('.auto_address_form');
            $addressForms.toArray().forEach((form) => {
              initAddressForm(form);
            });

            const $autocompleteAddressForm = $sliderForm.find('.autocomplete_address_form');
            $autocompleteAddressForm.toArray().forEach((form) => {
              new LocationAutocompleteWidget($(form));
            });

            const $input = $sliderForm.find(
              ':input:not(input[type=button],input[type=submit],button):visible:enabled:first',
            );
            setTimeout(() => {
              $input.trigger('focus');
            }, 100);

            $('table.sortable-table')
              .toArray()
              .forEach((table) => {
                initialiseSortableTable(table);
              });

            $sliderForm.find('input[type="checkbox"][data-toggle="toggle"]').bootstrapToggle();
          }

          $body.trigger('slider-loaded');
        }),
        onClose: this.getCallbackOption('onClose', () => undefined),
      };
    }
    $(window).on('resize', () => {
      this.setHeight();
    });

    $body
      .on('click', this.scopedSelector('.super-slider-dismiss'), () => {
        this.close();
      })
      .on('click', this.scopedSelector('.super-slider-outer-wrapper'), () => {
        this.close();
      });

    $(document).on('keyup', (keyUpEvent) => {
      if (keyUpEvent.keyCode === 27) {
        // escape
        if (!this.hasModalBehaviour()) {
          this.close();
        }
      }
    });
  }

  show() {
    this.setHeight();
    SuperSlider.addRootElementClasses();

    $(this.containerSelector).addClass('active');

    if (this.convertedOptions.callbacks) {
      this.convertedOptions.callbacks.open();
    }

    this.$slider.find('select.select2').select2({
      theme: 'bootstrap',
      minimumResultsForSearch: 10,
    });

    this.$slider.trigger('super-slider-shown');

    document.dispatchEvent(
      new CustomEvent<{ name?: string }>('super-slider-shown', { detail: { name: this.name } }),
    );
  }

  close() {
    if (this.convertedOptions.callbacks) {
      this.convertedOptions.callbacks.onClose(this);
    }

    $(this.containerSelector).removeClass('active');

    if ($('.super-slider-instance.active').length < 1) {
      SuperSlider.closeAll();
    }

    document.dispatchEvent(
      new CustomEvent<{ name?: string }>('super-slider-closed', { detail: { name: this.name } }),
    );
  }

  preSubmit() {
    if (this.request) {
      this.request.setShowLoadingModal();
      // FIXME - why is loadingModal being set to 'slider'?
      this.request.loadingModal = 'slider';
    }
  }

  assignCallback() {
    if (this.extraCallback) {
      this.$slider.trigger('super-slider-fetched');
      if (this.request) {
        this.request.extraCallback = this.extraCallback;
      }
    }
  }

  getCallbackOption(optionKey: string, defaultVal?: () => unknown): () => unknown {
    if (typeof this.options === 'object' && typeof this.options.callbacks === 'object') {
      if (typeof this.options.callbacks[optionKey] === 'function') {
        return this.options.callbacks[optionKey];
      }
    }

    return (
      defaultVal ??
      (() => {
        /* do nothing */
      })
    );
  }

  async submit() {
    if (this.request && this.request.isPending()) {
      this.request.abort();
    }
    if (!this.url) {
      throw new Error('No request url specified to submit a request to');
    }
    this.request = new E1Request(this.url, 'GET', this.options.data || {});
    this.preSubmit();
    this.assignCallback();
    return this.request.submit();
  }

  setHeight() {
    const windowHeight = $(window).height() ?? 0;

    const $footer = this.$slider.find('.super-slider-footer');
    const footerHeight = $footer.length ? ($footer.outerHeight() ?? 0) : 0;

    const $bottomBar = $('body').find('.bottom-bar');
    const bottomBarHeight = $bottomBar.length ? ($bottomBar.outerHeight() ?? 0) : 0;

    const desiredHeight = windowHeight - bottomBarHeight - this.windowMargin * 2;
    this.$slider.outerHeight(desiredHeight);

    const $sliderBody = this.$slider.find('.super-slider-body');
    if ($sliderBody.length) {
      const sliderOffset = this.$slider.offset()?.top ?? 0;
      const bodyOffset = $sliderBody.offset()?.top ?? 0;
      const relativeBodyOffset = bodyOffset - sliderOffset;
      const desiredBodyHeight = desiredHeight - relativeBodyOffset;
      $sliderBody.outerHeight(desiredBodyHeight - footerHeight);
    }
  }

  setUrl(routingUrl: string) {
    this.url = routingUrl;
    return this;
  }

  hasModalBehaviour() {
    return this.$slider.find('.super-slider-content-container').hasClass('modal-behaviour');
  }

  private scopedSelector(selector: string) {
    return `${this.containerSelector} ${selector}`;
  }
}
