export function getIEVersion() {
  const myNav = navigator.userAgent.toLowerCase();
  return myNav.indexOf('msie') !== -1 ? parseInt(myNav.split('msie')[1], 10) : false;
}

export function isMobileSafari() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);

  return iOS && webkit && !ua.match(/CriOS/i);
}

export function isValidKeyup(code: number) {
  return [37, 38, 39, 40].indexOf(code) === -1;
}

export function updateProgressBar($progressContainer: JQuery, count: number, percent: number) {
  $progressContainer
    .find('.progress-import .progress-bar')
    .attr('aria-valuenow', count)
    .css('width', `${percent}%`)
    .text(`${percent}%`);

  $progressContainer.find('.progress-target .processed').text(count);
}

export function getUserId(): number | null {
  if (typeof window.global !== 'undefined' && typeof window.global.user !== 'undefined') {
    return window.global.user.id;
  }

  return null;
}
