import $ from 'jquery';
import Routing from 'routing';
import E1Request from './E1Request';
import { getBoundsForLocaleBias } from '../utils/address_form';
import E1Address from './E1Address';
import ManualAddressForm from './ManualAddressForm';
import Modal from './Modal';

/**
 * @param {number} c
 * @return {boolean}
 */
const isValidCharForAddressEntry = (c) =>
  (c > 47 && c < 58) || // number keys
  c === 32 || // space
  c === 46 || // del
  c === 8 || // backspace
  (c > 64 && c < 91) || // letter keys
  (c > 95 && c < 112) || // numpad keys
  (c > 185 && c < 193) || // ;=,-./` (in order)
  (c > 218 && c < 223); // [\]' (in order)

export default class LocationAutocompleteWidget {
  static addressFields = [
    'address',
    'suburb',
    'city',
    'province',
    'state',
    'postcode',
    'country',
    'latitude',
    'longitude',
  ];

  /**
   * @param {jQuery} $target
   * @param {google.maps.places.AutocompleteOptions} autocompleteOptions
   */
  constructor($target, autocompleteOptions = {}) {
    this.$target = $target;
    this.$autocompleteField = this.$target.find('.location_autocomplete');
    this.autocompleteFieldNode = this.$autocompleteField.get(0);
    this.autocompleteOptions = {
      ...{ types: ['geocode'], bounds: getBoundsForLocaleBias() },
      ...autocompleteOptions,
    };
    this.hasValidPlace = false;

    /*
     * Fix for chrome autocomplete blocks google autosuggest when typing an address
     * https://stackoverflow.com/a/49161445/2503233
     */
    const autocompleteObserver = new MutationObserver(() => {
      autocompleteObserver.disconnect();
      this.$autocompleteField.attr('autocomplete', '_off_');
    });

    autocompleteObserver.observe(this.autocompleteFieldNode, {
      attributes: true,
      attributeFilter: ['autocomplete'],
    });

    /*
     * Create the autocomplete object,
     * biasing the search to geographical location of users' locale
     */
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteFieldNode,
      this.autocompleteOptions,
    );
    this.autoCompleteService = new google.maps.places.AutocompleteService();

    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));

    this.initInputListeners();
    this.initModalTriggerListener();
    this.initPlaceChangedListener();
  }

  initInputListeners() {
    this.$autocompleteField
      .keydown(({ which: code }) => isValidCharForAddressEntry(code) && this.clearAddressFields())
      // If the user hit enter, we don't want to submit the form
      .keypress(({ which: code }) => code !== 13)
      .blur(() => (this.hasValidPlace ? false : this.resetInput()));
  }

  initModalTriggerListener() {
    this.$target.on('click', 'a.manualaddress-trigger', async () => {
      const request = new E1Request(Routing.generate('app_ajax_manualaddress_modal'));

      // Override the default success handler
      const onSuccess = async (req, response) => {
        const { modal_string: modalString } = response;

        if (modalString) {
          Modal.closeAll();
          new Modal(response.modal_string).show();
          const container = $.magnificPopup.instance.contentContainer;
          return new ManualAddressForm($(container).find('form.manual-address-form'), this);
        }

        return E1Request.successResponse(req, response);
      };

      return request.submit(onSuccess);
    });
  }

  async handlePlaceChanged() {
    const place = this.autocomplete.getPlace();

    const { address_components: addressComponents, name } = place;

    if (addressComponents !== undefined) return this.handlePlaceSelection(place);

    try {
      /** @type AutocompletePrediction[] */
      const prediction = await this.getPlacePredictions(name);
      const details = await this.getPlaceDetails(prediction[0].place_id);
      this.$autocompleteField.addClass('requires-refresh');
      return this.handlePlaceSelection(details);
    } catch (noResultsError) {
      this.clearAddressFields();
      this.resetInput();
    }

    return undefined;
  }

  /**
   * @param {string} name
   * @return {Promise<AutocompletePrediction[]>}
   */
  async getPlacePredictions(name) {
    const { types, bounds } = this.autocompleteOptions;
    return new Promise((resolve, reject) => {
      this.autoCompleteService.getPlacePredictions(
        {
          input: name,
          offset: name.length,
          // Repeat the options for my AutoComplete here to get the same results
          types,
          bounds,
        },
        (list, status) =>
          list && list.length && status === google.maps.places.PlacesServiceStatus.OK
            ? resolve(list)
            : reject(new Error('No predictions available')),
      );
    });
  }

  /**
   * @param {string} placeId
   * @return {Promise<PlaceResult>}
   */
  async getPlaceDetails(placeId) {
    return new Promise((resolve, reject) => {
      this.placesService.getDetails(
        {
          placeId,
        },
        (placeResult, status) =>
          status === google.maps.places.PlacesServiceStatus.OK
            ? resolve(placeResult)
            : reject(new Error(`Place lookup failed with status ${status}`)),
      );
    });
  }

  initPlaceChangedListener() {
    const that = this;
    this.autocomplete.addListener('place_changed', async function handler() {
      return that.handlePlaceChanged(this.getPlace());
    });
  }

  /**
   * @param {PlaceResult} place
   */
  handlePlaceSelection(place) {
    const address = E1Address.fromGoogleResult(place);
    this.setAddressFields(address);

    if (this.$autocompleteField.hasClass('requires-refresh')) {
      this.setInputBasedOnAddressFields(address);
      this.$autocompleteField.removeClass('requires-refresh');
    }
  }

  clearAddressFields() {
    this.hasValidPlace = false;
    this.constructor.addressFields
      .map((f) => `.${f}-field`)
      .forEach((cls) => this.$target.find(cls).val(''));
  }

  /**
   * Insert address into the form to be submitted to the api.
   * @param {E1Address} address
   */
  setAddressFields(address) {
    const addressString = ['address', 'address2', 'address3']
      .map((k) => address[k] || null)
      .filter(Boolean)
      .join(' ');

    this.$target.find('.address-field').val(addressString);
    this.constructor.addressFields
      .filter((k) => k !== 'address')
      .map((f) => [f, `.${f}-field`])
      .forEach(([f, cls]) => this.$target.find(cls).val(address[f]));

    this.hasValidPlace = true;
  }

  resetInput() {
    this.$autocompleteField.val('');
  }

  /**
   * Renders address into the Google autocomplete field.
   * @param {E1Address} address
   */
  setInputBasedOnAddressFields(address) {
    this.$autocompleteField.val(address.toShortString());
  }
}
